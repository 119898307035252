import feathersClient, { makeServicePlugin, BaseModel } from '../feathers-client';
// eslint-disable-next-line import/named
import { genId, meta } from '../tools';

class Project extends BaseModel {
  // Required for $FeathersVuex plugin to work after production transpile.
  static modelName = 'Project'

  // Define default properties here
  static instanceDefaults(data) {
    return {
      uuid: genId(data.ref, 'projects'),
      name: '',
      desc: '',
      localPath: null,
      libraries: [],
      ...meta(),
    };
  }
}
const servicePath = 'projects';
const servicePlugin = makeServicePlugin({
  Model: Project,
  service: feathersClient.service(servicePath),
  servicePath,
});

const authUrl = process.env.VUE_APP_AUTH_URL || 'https://auth.thimble.io';

// Set up the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [
      async (context) => {
      // limit
      //   const limit = 10;
        const limit = context.params.query.limit || 99999;
        const { sort } = context.params.query;
        let query = `limit=${limit}`;

        if (sort) {
          query += '&sortKey=createdAt&sortDir=desc';
        }
        const resp = await fetch(`${authUrl}/api/ide/projects?${query}`, { credentials: 'include' });

        const { projects } = await resp.json();

        return {
          ...context,
          result: projects.map((project) => ({
            ...project,
            id: project.id,
            _id: project.id,
            uuid: project.id,
          })),
        };
      },
    ],
    get: [],
    create: [
      async (context) => {
        const resp = await fetch(`${authUrl}/api/ide/projects`,
          {
            body: JSON.stringify(context.data),
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            credentials: 'include',
          });

        const { project } = await resp.json();

        return {
          ...context,
          data: {
            ...context.data,
            _id: project.id || context.data._id,
            id: project.id || context.data.id,
            uuid: project.id || context.data.uuid,
          },
        };
      },
    ],
    update: [
      async (context) => {
        const resp = await fetch(`${authUrl}/api/ide/projects`,
          {
            body: JSON.stringify(context.data),
            headers: {
              'Content-Type': 'application/json',
            },
            method: 'PATCH',
            credentials: 'include',
          });
        const { project } = await resp.json();

        return {
          ...context,
          data: {
            ...project,
            _id: project.id || context.data._id,
            id: project.id || context.data.id,
            uuid: project.id || context.data.uuid,
          },
        };
      },
    ],
    patch: [
      async (context) => {
        const resp = await fetch(`${authUrl}/api/ide/projects`,
          {
            body: JSON.stringify(context.data),
            headers: {
              'Content-Type': 'application/json',
            },
            method: 'PATCH',
            credentials: 'include',
          });

        const { project } = await resp.json();

        return {
          ...context,
          data: {
            ...project,
            _id: project.id || context.data._id,
            id: project.id || context.data.id,
            uuid: project.id || context.data.uuid,
          },
        };
      },
    ],
    remove: [
      async (context) => {
        const projectId = context.id;
        await fetch(`${authUrl}/api/ide/projects?id=${projectId}`,
          {
            body: JSON.stringify(context.data),
            headers: {
              'Content-Type': 'application/json',
            },
            method: 'DELETE',
            credentials: 'include',
          });

        return context;
      },
    ],
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
});

export default servicePlugin;